@import (reference) "../../styles/colors.less";

@toggleThumbPadding: 4px;
@toggleWidth: 34px;
@toggleThumbDiameter: 14px;
@toggleHeight: 20px;

.toggle {
	display: inline-flex;
	align-items: center;

	&.round {
		.toggle-track {
			border-radius: 14px;

			.toggle-thumb {
				border-radius: 50%;
				cursor: pointer;
			}
		}
	}

	input {
		display: none;

		&:checked {
			& + .toggle-track {
				background-color: @toggleOn;

				.toggle-thumb {
					transform: translateX(@toggleWidth - @toggleThumbDiameter - 2*@toggleThumbPadding);
				}
			}
		}
	}

	.toggle-track {
		background-color: @toggleOff;
		transition: 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
		display: flex;
		align-items: center;
		padding: 0 @toggleThumbPadding;
		width: @toggleWidth - 2*@toggleThumbPadding;
		height: @toggleHeight;
		overflow: hidden;
		box-sizing: content-box;

		.toggle-thumb {
			background: #FFFFFF;
			box-shadow: 0 0 2px 0 rgba(0,0,0,0.10);
			transition: 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
			width: @toggleThumbDiameter;
			height: @toggleThumbDiameter;
			position: relative;
		}
	}

	.toggle-disabled {
		&,
		& ~ .toggle-text {
			cursor: not-allowed;
			opacity: 0.5;
			transition: 'opacity 0.25s';
		}
	}

	&-text {
		margin-left: 10px;
		font-size: 12px;
		color: #8E8E8E;
	}
}
