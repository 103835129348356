@import (reference) '../../styles/colors.less';

@selectedTabbarItemBottomBorderHeight: 3px;
@tabbarHeight: 60px;
@tabbarBottomBorderHeight: 1px;

.tab-view {
	display: flex;
	flex-direction: column;

	&-scroll-view {
		height: 100%;
	}

	&-tabbar {
		background: #fff;
		display: flex;
		height: @tabbarHeight;

		&-tabs {
			border-bottom: @tabbarBottomBorderHeight solid #e3e7e9;
			box-sizing: border-box;
			display: flex;
			margin: 0;
			padding: 0;
			position: relative;
			width: 100%;
		}

		&-item {
			flex-grow: 1;
			flex-basis: 0;
			font-size: 14px;
			color: #767676;
			justify-content: center;
			align-items: center;
			display: flex;
			text-transform: uppercase;
			letter-spacing: 1.2px;
			text-decoration: none;

			&:active {
				text-decoration: none;
			}

			&.tabbar-item-selected {
				color: @brandPrimary;
			}
		}

		&-selection-indicator {
			height: @selectedTabbarItemBottomBorderHeight;
			position: absolute;
			bottom: -1px;
			left: 0;
			transition: 0.2s;
			background-color: @brandPrimary;		
		}
	}

	&-content {
		flex-grow: 1;
	}
}
