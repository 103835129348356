@import (reference) "../../../styles/colors.less";
@import (reference) "../../../styles/inputs.less";

.rich-content-document-editor-readonly {
	ol, ul, li {
		list-style: initial;
		padding: initial;
	}

	/* Browser default styles */
	ol, ul {
		margin-block-end: 1em;
		margin-block-start: 1em;
		padding-inline-start: 40px;
		padding-left: 40px;
	}

	ul > li {
		line-height: 1.4;
		list-style-type: initial;
	}

	ol > li {
		line-height: 1.4;
		list-style-type: decimal;
	}
}

/**
* Style overrides for editor controls
*/
.tox.tox-silver-sink.tox-tinymce-aux {
	// default is 1300... absurd.
	// caused floating toolbar to appear above all of our modals
	z-index: 5;

	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		z-index: auto;
		
		.tox-toolbar__overflow, .tox-menu {
			z-index: 5;
		}
	}
}

.rich-content-document-editor {
	.tox.tox-tinymce {
		border: none;

		.tox-editor-container {
			.tox-toolbar__group,
			.tox-toolbar,
			.tox-toolbar-overlord {
				*[title="Font sizes"] {
					.tox-tbtn__select-label {
						width: 52px;
					}
				}
				*[title="Fonts"] {
					.tox-tbtn__select-label {
						width: 80px;
					}
				}

				.tox-toolbar__primary {
					border-top: none;
					border-bottom: none;
					background: none;
				}
			}
		}

		.tox-edit-area {
			border: none;

			iframe {
				background: none;
				background-color: transparent;
			}
		}
	}
}

/**
* Style overrides for dialogs
*/

/**
* Target IE11: fixes z-index problem w/TinyMce Modals (Win10)
*/
_:-ms-fullscreen, :root body[class*=tox-dialog] {
	position: relative;
}

.tox.tox-tinymce-aux {	
	.tox-dialog {
		font-family: "Sora", sans-serif;

		.tox-textfield {
			.text-field();
		}

		.tox-button:not(.tox-button--icon) {
			.cta-button();
		
			&.tox-button--secondary {
				.cta-button-reverse();
			}
		}

		.tox-form__group {
			.tox-label {
				color: #4A4A4A;
				margin-bottom: 6px;
			}
		}

		.tox-selectfield {
			select {
				.text-field();
			}
		}

		.tox-dialog__footer {
			border: none;
		}
	}
}
