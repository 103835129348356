@import (reference) '../../../styles/colors.less';

.contact-context-tabview {
	.scroll-view {
		overflow: unset;

		.scroll-view-content {
			overflow-x: hidden;
			position: initial;
			&::-webkit-scrollbar {
				-webkit-appearance: none;
				width: 9px;
			}
			  
			&::-webkit-scrollbar-thumb {
				border-radius: 4px;
				background-color:  #444;
				box-shadow: 0 0 1px rgba(255, 255, 255, .5);
			}			  
		}

		&-body {
			height: ~'calc(100% - 35px)';
			overflow-y: auto;
			overflow-x: hidden;
		}
	}

	.tab-view-tabbar {
		height: 35px;
		z-index: 3;

		.tab-view-tabbar-item {
			font-size: 13px;
			letter-spacing: 1.1px;
		}
	}

	&-entity-notes {
		.activity-list-item {
			padding: 10px 5px;

			.note-editor {
				margin-top: 0;

				&-body {
					min-height: initial;
					padding: 10px 0 15px 0;

					&-editor {
						min-height: initial;
					}
				}
			}
		}
	}

	&-history {
		.activity-list-item {
			padding: 10px 5px;
		}
	}
}
