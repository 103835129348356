@import (reference) "../../../styles/colors.less";

.add-entity-buttons {
	display: flex;
	justify-content: space-between;
	padding: 10px 20px;
	
	button {
		align-items: center;
		color: @brandPrimaryText;
		display: flex;
		font-size: 14px;
		justify-content: center;

		&:not(:first-child) {
			margin-left: 20px;
		}

		.add-entity-button-icon {
			align-items: center;
			display: flex;
			height: 30px;
			justify-content: center;
			width: 30px;
		}

		.add-entity-button-title {
			margin-left: 20px;
		}
	}
}