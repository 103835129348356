@import (reference) "../../../styles/inputs.less";

@tokenTopMargin: 4px;
@tokenLineHeight: 31px;
@labelWidth: 20px;
@avatarSize: 35px;

.email-cc-field {
	position: relative;
	.text-field();
	max-height: 2*@tokenLineHeight;
	width: 100%;
	overflow-x: hidden;
	overflow-y: auto;

	&-label {
		color: #8E8E8E;
		font-size: 12px;
		left: @textFieldPadding;
		position: absolute;
		top: 6px + @textFieldPadding;
		width: @labelWidth;
	}

	&-content {
		align-items: center;
		display: flex;
		width: ~"calc(100% - @{textFieldPadding})";
		margin-left: @labelWidth;
		margin-top: -@tokenTopMargin;
		min-height: @tokenLineHeight;
		

		&-input {
			background: transparent;
			border: 0;
			padding: 2px 0;
			margin-left: 10px;
			flex-grow: 1;
			display: inline-flex;
			min-width: 100px;

			&:focus {
				outline: none;
			}
		}
	}

	&-token {
		align-items: center;
		border-radius: 3px;
		border: 1px solid @brandSecondary;
		box-sizing: border-box;
		display: inline-flex;
		justify-content: center;
		margin-left: 10px;
		margin-top: @tokenTopMargin;
		max-width: 70%;
		padding: 4px 10px;
		position: relative;

		&-text {
			font-size: 12px;
			color: #767676;
			letter-spacing: 0;
		}

		&:hover {
			.email-cc-field-token-remove-button {
				display: flex;
			}
		}

		&-remove-button {
			align-items: center;
			display: flex;
			display: none;
			justify-content: center;
			margin: 0 -5px 0 0;
			padding: 5px;
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	&-tokens {
		display: flex;
		flex-grow: 1;
		flex-wrap: wrap;
	}

	&-no-matches {
		font-size: 14px;
		color: #000000;
		letter-spacing: 0;
		margin: 18px 20px;
	}

	&-dropdown {
		width: calc(100vw - 463px);

		&-content {
			background: #FFFFFF;
			border: 1px solid #F3F3F3;
			border-radius: 3px;
			box-shadow: 0 0 6px 0 rgba(139,139,139,0.10);
			box-sizing: border-box;
			width: 100%;

			.email-cc-field-recipient {
				align-items: center;
				cursor: pointer;
				display: flex;
				padding: 10px;

				&-avatar {
					width: @avatarSize;
					height: @avatarSize;
					margin-right: 10px;
				}

				&-details {
					width: ~"calc(100% - @{avatarSize} - 10px)";

					&-name {
						font-size: 14px;
						color: #000000;
					}

					&-email {
						font-size: 12px;
						color: @navigation;
						letter-spacing: 0;
					}
				}
				
				
				&:hover {
					background-color: #E3E7E9;
				}

				&:focus {
					outline: none;
				}

				&.email-cc-field-recipient-highlighted {
					background-color: #e8f3f9;
				}
			}

			&-overflow-footer {
				border-top: 1px solid #F3F3F3;
				color: #000000;
				font-size: 14px;
				padding: 10px;
			}
		}
	}
}