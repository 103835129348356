@import (reference) "../../../styles/inputs.less";

.meeting-attendees-header {
	font-size: 12px;
	color: #575D63;

	&-attendee {
		.minor-action-link();
		cursor: pointer;
	}

	&-popover {
		cursor: pointer;

		&-trigger {
			cursor: pointer;
			color: #0D0F11;
			padding: 5px;
			margin: -5px;
		}

		&-content {
			padding: 0;
		}

		.meeting-attendees-header-contact-list {
			max-width: 260px;
			box-sizing: border-box;

			&-item {
				padding: 10px 30px;
				box-sizing: border-box;

				&:not(:last-child) {
					border-bottom: 1px solid #E3E7E9;
				}
			}
		}
	}
}