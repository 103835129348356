@import (reference) "../../styles/colors.less";

@whitePopoverBorder: 1px solid #F3F3F3;

.z-index-buffer-1 {
	z-index: 1;
}

.z-index-buffer-2 {
	z-index: 2;
}

.z-index-buffer-3 {
	z-index: 3;
}

.z-index-buffer-4 {
	z-index: 4;
}

.Popover {
	z-index: 5;
	transition: none !important;
	transform: translateY(0) !important;

	&.popover-content-error {
		.popoverGenerator(@error, 10px, 2px, 0 1px 2px 0 rgba(162,162,162,0.50), 0, @error);
		.popover-content {
			padding: 18px 30px;
			font-weight: 600;
			font-size: 14px;
			color: #FFFFFF;
		}
	}

	&.popover-content-white {
		.popoverGenerator(#fff, 10px, 3px, 0 1px 2px 0 rgba(162,162,162,0.50), 1px, #f3f3f3);
	}

	&.popover-content-gray {
		.popoverGenerator(#F9F9F9, 0px, 0px, transparent, 1px, #A7ABAD);
	}

	&.popover-content-blue {
		.popoverGenerator(#E8F3F9, 20px, 3px, 0px 0px 6px rgba(139, 139, 139, 0.1), 1px, #D3DDBE);
	}

	&.popover-content-email-guide {
		.popoverGenerator(@brandPrimaryHover, 15px, 20px, 0 1px 2px 0 rgba(162,162,162,0.50), 0px, transparent);
	}
}

.popoverGenerator(@backgroundColor, @arrowSize, @borderRadius, @shadow, @borderWidth, @borderColor) {
	.Popover-body {
		display: inline-flex;

		.popover-content {
			box-shadow: @shadow;
			border-radius: @borderRadius;
			background: @backgroundColor;
			border-width: @borderWidth;
			border-color: @borderColor;
			border-style: solid;

			&.popover-content-plugin {
				width: 315px;
			}
		}
	}
	
	.Popover-tip {
		fill: @backgroundColor;
		stroke: @borderColor;
		stroke-width: @borderWidth;
		filter: drop-shadow(0 1px 1px rgba(162, 162, 162, .5));
	}
}