.scroll-view {
	position: relative;

	&-content {
		box-sizing: border-box;
		height: 100%;
		overflow: auto;
		position: relative;
	}

	&-header {
		left: 0;
		pointer-events: none;
		position: sticky;
		right: 0;
		top: 0;
		z-index: 1;

		&-content {
			pointer-events: auto;
		}
	}
}