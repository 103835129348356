@import (reference) "../../styles/colors.less";

.date-range-picker {
	&-header {
		text-align: right;
	}

	&-title {
		color: @brandSecondary;
		font-size: 18px;
		line-height: 22px;
	}

	&-body {
		align-items: center;
		display: flex;
		margin-top: 16px;

		& > * {
			flex: 1;
		}
	}

	&-calendar {
		&-day-picker-header {
			background: #F9F9F9;
			border-radius: 3px;
			border: 1px solid #F2F2F2;
			box-sizing: border-box;
			display: flex;
			margin: 0 18px 10px 18px;
			padding: 10px 12px;

			& > *:not(:first-child) {
				align-items: center;
				display: flex;
				margin-left: 10px;
			}
		}

		&-title {
			color: @navigation;
			font-size: 14px;
			letter-spacing: 0;
		}

		&-date {
			color: @titles;
			font-size: 14px;
			letter-spacing: 0;
		}

		&-day-picker {
		}

		&-date-icon.calendar-date-icon {
			height: 16px;
			width: 16px;
		}
	}

	&-footer {
		margin-top: 30px;

		&-submit-button {
		}
	}
}