.tagsAutocompleteContainer {
	border: 1px solid #e3e7e9;
	box-shadow: 0px 2px 4px 0px #e3e7e9;
	font-size: 14px;
	color: #0d0f11;
	background-color: white;
	min-width: 200px;
	padding: 5px 5px 5px 5px;
	margin-top: 2px;
	position: absolute;
	z-index: 2;
}

.tagsAutocompleteContainer:last-child {
	border-bottom: none !important;
}

.tagsAutocompleteLoaderContainer {
	text-align: center;
}

.tagsAutocompleteOptionContainer, .tagsAutocompleteOptionContainerLast {
	color: #0d0f11;
	background-color: white;
	cursor: pointer;
	padding: 10px;
	border-bottom:  1px solid #f0eded;
}

.tagsAutocompleteOptionContainerLast {
	border-bottom: 0;
}

.tagsAutocompleteOptionContainer:hover, .tagsAutocompleteOptionContainerLast:hover {
	background-color: #e3e7e9;
	cursor: pointer;
}

.cursorNormal {
	cursor: auto !important;
}